import actionTypes from './RecordsSummaryConstants';

const defaultValue = {
  totals: {
    Errors: 0,
    resolved: 0,
    total: 0,
    DmfCount: 0,
    MismatchesCount: 0,
    OfacRankCount: 0,
    NonPrinted: 0,
    NonTransmitted: 0,
    TotalForms: 0
  }
};

const initialStore = {
  summary: [],
  fetchingSummary: false,
  fetchingSummarySorted: false,
  totals: defaultValue.totals,
  fetchingRecordsByFormSummary: false,
  recordsByFormSummary: [],
  fetchingRecordsByForm: false,
  recordsByForm: {},
  fetchingTINMismatchesSummary: false,
  TINMismatchesSummary: [],
  fetchingMismatchesByCode: false,
  mismatchesByCode: {},
  fetchingOfacAlerts: false,
  ofacAlerts: {
    Data: [],
    CurrentPage: 0,
    Total: 0
  },
  fetchingDmfAlerts: false,
  dmfAlerts: {
    Data: [],
    CurrentPage: 0,
    Total: 0
  }
};

export default function RecordsSummaryReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    case actionTypes.FETCHING_RECORDS_BY_FORM_SUMMARY:
      return { ...state, fetchingRecordsByFormSummary: true };

    case actionTypes.FETCHING_RECORDS_BY_FORM_SUMMARY_SUCCESS:
      return { ...state, fetchingRecordsByFormSummary: false, recordsByFormSummary: action.summary };

    case actionTypes.FETCHING_RECORDS_BY_FORM_SUMMARY_FAILURE:
      return { ...state, fetchingRecordsByFormSummary: false, recordsByFormSummary: [] };

    case actionTypes.FETCHING_RECORDS_BY_FORM:
      return { ...state, fetchingRecordsByForm: true };

    case actionTypes.FETCH_RECORDS_BY_FORM_SUCCESS:
      return { ...state, fetchingRecordsByForm: false, recordsByForm: action.records };

    case actionTypes.FETCH_RECORDS_BY_FORM_FAILURE:
      return { ...state, fetchingRecordsByForm: false, recordsByForm: {} };

    case actionTypes.FETCHING_TIN_MISMATCHES_SUMMARY:
      return { ...state, fetchingTINMismatchesSummary: true };

    case actionTypes.FETCHING_TIN_MISMATCHES_SUMMARY_SUCCESS:
      return { ...state, fetchingTINMismatchesSummary: false, TINMismatchesSummary: action.summary };

    case actionTypes.FETCH_TIN_MISMATCHES_SUMMARY_FAILURE:
      return { ...state, fetchingTINMismatchesSummary: false };

    case actionTypes.FETCHING_MISMATCHES_BY_CODE:
      return { ...state, fetchingMismatchesByCode: true };

    case actionTypes.FETCH_MISMATCHES_BY_CODE_SUCCESS:
      return { ...state, fetchingMismatchesByCode: false, mismatchesByCode: action.records };

    case actionTypes.FETCH_MISMATCHES_BY_CODE_FAILURE:
      return { ...state, fetchingMismatchesByCode: false };

    case actionTypes.FETCHING_DMF_ALERTS:
      return { ...state, fetchingDmfAlerts: true, dmfAlerts: initialStore.dmfAlerts };

    case actionTypes.FETCH_MISMATCHES_DMF_ALERTS_SUCCESS:
      return { ...state, fetchingDmfAlerts: false, dmfAlerts: action.records };

    case actionTypes.FETCH_MISMATCHES_DMF_ALERTS_FAILURE:
      return { ...state, fetchingDmfAlerts: false };

    case actionTypes.FETCHING_OFAC_ALERTS:
      return { ...state, fetchingOfacAlerts: true, ofacAlerts: initialStore.ofacAlerts };

    case actionTypes.FETCH_MISMATCHES_OFAC_ALERTS_SUCCESS:
      return { ...state, fetchingOfacAlerts: false, ofacAlerts: action.records };

    case actionTypes.FETCH_MISMATCHES_OFAC_ALERTS_FAILURE:
      return { ...state, fetchingOfacAlerts: false };

    default:
      return state;
  }
}
