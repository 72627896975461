import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_RECORDS_BY_FORM_SUMMARY: 'FETCHING_RECORDS_BY_FORM_SUMMARY',
  FETCHING_RECORDS_BY_FORM_SUMMARY_SUCCESS: 'FETCHING_RECORDS_BY_FORM_SUMMARY_SUCCESS',
  FETCHING_RECORDS_BY_FORM_SUMMARY_FAILURE: 'FETCHING_RECORDS_BY_FORM_SUMMARY_FAILURE',
  FETCHING_RECORDS_BY_FORM: 'FETCHING_RECORDS_BY_FORM',
  FETCH_RECORDS_BY_FORM_SUCCESS: 'FETCH_RECORDS_BY_FORM_SUCCESS',
  FETCH_RECORDS_BY_FORM_FAILURE: 'FETCH_RECORDS_BY_FORM_FAILURE',
  FETCHING_TIN_MISMATCHES_SUMMARY: 'FETCHING_TIN_MISMATCHES_SUMMARY',
  FETCHING_TIN_MISMATCHES_SUMMARY_SUCCESS: 'FETCHING_TIN_MISMATCHES_SUMMARY_SUCCESS',
  FETCH_TIN_MISMATCHES_SUMMARY_FAILURE: 'FETCH_TIN_MISMATCHES_SUMMARY_FAILURE',
  FETCHING_MISMATCHES_BY_CODE: 'FETCHING_MISMATCHES_BY_CODE',
  FETCH_MISMATCHES_BY_CODE_SUCCESS: 'FETCH_MISMATCHES_BY_CODE_SUCCESS',
  FETCH_MISMATCHES_BY_CODE_FAILURE: 'FETCH_MISMATCHES_BY_CODE_FAILURE',
  FETCHING_DMF_ALERTS: 'FETCHING_DMF_ALERTS',
  FETCH_MISMATCHES_DMF_ALERTS_SUCCESS: 'FETCH_MISMATCHES_DMF_ALERTS_SUCCESS',
  FETCH_MISMATCHES_DMF_ALERTS_FAILURE: 'FETCH_MISMATCHES_DMF_ALERTS_FAILURE',
  FETCHING_OFAC_ALERTS: 'FETCHING_OFAC_ALERTS',
  FETCH_MISMATCHES_OFAC_ALERTS_SUCCESS: 'FETCH_MISMATCHES_OFAC_ALERTS_SUCCESS',
  FETCH_MISMATCHES_OFAC_ALERTS_FAILURE: 'FETCH_MISMATCHES_OFAC_ALERTS_FAILURE'
};

export default actionTypes;
