import TinMask from 'shared/dataModifier/tinMask/TinMask';
import { getTinTypeName } from 'helpers/tinConstants';

export default [
  {
    title: 'Name',
    id: 1,
    sortIndicator: 'Name',
    component: ({ content }) => content.Name ? content.Name : ''
  },
  {
    title: 'TIN',
    id: 2,
    sortIndicator: 'Tin',
    component: ({ content }) => <TinMask records={ { TIN: content.Tin, TINTYPE: content.TinType } } /> // eslint-disable-line react/display-name, react/prop-types
  },
  {
    title: 'TIN type',
    id: 3,
    sortIndicator: 'TinType',
    component: ({ content }) => content.TinType ? getTinTypeName(content.TinType) : ''
  },
  {
    title: 'Account #',
    id: 4,
    width: 'greedy',
    sortIndicator: 'AccountNo',
    component: ({ content }) => content.AccountNo ? content.AccountNo : ''
  }
];
