import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { shape, object, bool, func, array } from 'prop-types';
import { useAuth } from 'react-oidc-context';
import classnames from 'classnames';
import { withSnackbar } from 'enhancers';
import SovosGlobalLayout from 'mosaic-react/sovos-global-layout/SovosGlobalLayout';
import SuspenseLoadingPage from 'shared/suspenseLoadingPage/SuspenseLoadingPage';
import authenticationHelper from 'helpers/authenticationHelper';
import { TIR_ENT_SECURITY_ADMIN, TIR_NAVIGATE_RTT_LANDING_PAGE, TIR_NAVIGATE_WF_LANDING_PAGE } from 'helpers/permissionConstants';
import { authRoutes, handleHelpClick } from '../auth/Routes';
import AutomaticLogoutChildProvider from 'contexts/automaticLogout/AutomaticLogoutChildProvider';
import Eula from 'components/eula/EulaConnector';
import ClientInformation from 'components/clientInformation/ClientInformationConnector';
import FullPageLoader from 'enhancers/loaders/FullPageLoader';
import { structureTypes } from 'pages/manageUsersPage/helpers/structuresRolesHelper';
import useAutomaticLogout from 'contexts/automaticLogout/useAutomaticLogout';
import useContextHasPayers from 'helpers/useContextHasPayers';
import './App.scss';

const getInitialPage = () => {
  if (authenticationHelper.isTIRCorporate()) {
    return 'Home';
  }

  if (authenticationHelper.isTIREnterprise()) {
    return 'Overview';
  }

  return '';
};

const pathWithoutNav = [
  '/new-payer'
];

const App = ({
  isFetchingForms,
  isFetchingPermission,
  selectedContext,
  contexts,
  contextActions,
  formMetaInformationActions,
  manageUserActions,
  showSnackbar
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();

  const { idleAlert } = useAutomaticLogout();

  const { shouldRedirect } = useContextHasPayers();

  const solution = sessionStorage.tirProduct;

  const [layoutKey, setLayoutKey] = useState('');
  const [selectedLink, setSelectedLink] = useState(getInitialPage());
  const [container, setContainer] = useState(null);
  const [shouldRenderClientInfo, setShouldRenderClientInfo] = useState(true);

  useEffect(() => {
    showSnackbar({
      open: idleAlert,
      message: 'You will be logged out due to inactivity in 5 minutes. Please take action to keep your session alive.',
      variant: 'warning',
      actionLabel: 'OK',
      classes: {
        content: 'tir-sovosSnackbar_automatic-logout__content'
      },
      onClose: () => {}
    });
  }, [idleAlert, showSnackbar]);

  useEffect(() => {
    const footer = document.querySelector('.sovosNavigationFooter');

    if (footer && !container) {
      const newContainer = document.createElement('div');
      newContainer.className = 'client-info-container';

      footer.parentNode.insertBefore(newContainer, footer);
      setContainer(newContainer);
    }
  }, [container]);

  useEffect(() => {
    const navElement = document.querySelector('.sovosNavigation');

    if (navElement) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'class') {
            const classList = mutation.target.classList;
            if (classList.contains('sovosNavigation--minimal')) {
              setShouldRenderClientInfo(false);
            } else {
              setShouldRenderClientInfo(true);
            }
          }
        });
      });

      observer.observe(navElement, { attributes: true });

      return () => observer.disconnect();
    }
  }, []);

  const addSelectStateAction = useCallback(route => {
    const { label, subLinks } = route;

    const handleNavigate = url => {
      if (url !== window.location.pathname) {
        setSelectedLink(label);
        navigate(url);
      }
    };

    return {
      ...route,
      action: subLinks
        ? null
        : () => handleNavigate(`/${ route.path.includes('/*') ? route.path.split('/*')[0] : route.path }`),
      nestedLinks: subLinks
        ? subLinks
          .filter(route => !route.internal)
          .filter(route => authenticationHelper.checkProduct(route.product))
          .filter(authenticationHelper.checkConfigurationsAndPermissions)
          .map(addSelectStateAction)
        : null
    };
  }, [navigate]);

  const getNavLinks = useCallback(() =>
    authRoutes
      .filter(route => !route.internal)
      .filter(route => authenticationHelper.checkProduct(route.product))
      .filter(authenticationHelper.checkConfigurationsAndPermissions)
      .map(addSelectStateAction), [addSelectStateAction]);

  const getSettingsMenuItems = () => [
    {
      label: 'Settings',
      action: () => navigate('/settings')
    }
  ];

  const checkPayers = async () => {
    try {
      const response = await shouldRedirect();
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const selectNewContext = async context => {
    if (context.structureType === structureTypes.TAXYEAR) {
      const customAttributes = JSON.parse(context.customAttributes);
      contextActions.setContext({
        ...context,
        name: customAttributes.contextType.toUpperCase(),
        taxYear: String(customAttributes.taxYear),
        customAttributes
      });
      formMetaInformationActions.resetMetadata();
      await manageUserActions.fetchContextInfo();
      if (!authenticationHelper.checkAllUserPermissions([TIR_NAVIGATE_RTT_LANDING_PAGE])) {
        manageUserActions.fetchForms();
      }
      setLayoutKey(`${selectedContext.name}-${selectedContext.taxYear}`);
      setSelectedLink(getInitialPage());

      if (authenticationHelper.checkAllUserPermissions([TIR_NAVIGATE_WF_LANDING_PAGE])) {
        if (authenticationHelper.isTIRCorporate()) {
          const redirectToNewPayerPage = await checkPayers();
          redirectToNewPayerPage ? navigate('/new-payer') : navigate('/data');
        } else {
          navigate('/data/records');
        }
      } else if (authenticationHelper.checkAllUserPermissions([TIR_NAVIGATE_RTT_LANDING_PAGE])) {
        navigate('/realtimetin');
      } else {
        const redirectToNewPayerPage = await checkPayers();
        redirectToNewPayerPage ? navigate('/new-payer') : navigate(authenticationHelper.getProductLandingPage());
      }
    }
  };

  const handleLogout = async () => {
    navigate('/logout');
  };

  const getUserMenuItems = () => {
    if (authenticationHelper.isInternalRole()) {
      return [
        { label: 'Please close the current tab manually', action: () => {} }
      ];
    } else {
      return [
        { label: 'Logout', action: handleLogout },
        { label: 'Co-browse with Support', action: () => acquireIO.max() } //eslint-disable-line no-undef
      ];
    }
  };

  const hideDashboardItems = pathWithoutNav.some(path => path === location.pathname);

  const getNavProps = () => ({
    product: authenticationHelper.getProductName(),
    products: [],
    links: getNavLinks(),
    selectedLink: selectedLink,
    initialSelectedLink: '',
    accounts: authenticationHelper.checkAllUserPermissions([TIR_ENT_SECURITY_ADMIN]) ? [] : contexts,
    selectedAccount: selectedContext,
    setAccount: context => selectNewContext(context),
    slotProps: {
      showSettingsButton: !authenticationHelper.checkSomeUserPermissions([TIR_NAVIGATE_RTT_LANDING_PAGE, TIR_NAVIGATE_WF_LANDING_PAGE]),
      showHelpButton: true,
      userMenu: {
        email: auth.user?.profile.email || ' ',
        name: auth.user?.profile.firstName || ' ',
        surname: auth.user?.profile.lastName || ' ',
        items: getUserMenuItems()
      },
      settingsMenu: {
        items: getSettingsMenuItems()
      },
      helpButton: {
        onClick: handleHelpClick
      }
    },
    className: classnames({ 'sovosNavigation-disable': hideDashboardItems })
  });

  const renderClientInfo = () => {
    if (!shouldRenderClientInfo || !container) {
      return null;
    }

    return createPortal(<ClientInformation />, container);
  };

  return (
    <>
      <SovosGlobalLayout
        NavigationProps={ getNavProps() }
        key={ layoutKey }
      >
        <Suspense fallback={ <SuspenseLoadingPage /> }>
          <Outlet />
        </Suspense>
      </SovosGlobalLayout>
      {
        solution && <Eula />
      }
      <FullPageLoader show={ isFetchingForms || isFetchingPermission } />
      { renderClientInfo() }
    </>
  );
};

App.propTypes = {
  manageUserActions: shape({
    fetchForms: func,
    fetchContextInfo: func
  }).isRequired,
  formMetaInformationActions: shape({
    resetMetadata: func
  }).isRequired,
  selectedContext: object,
  //TODO: After migrating remove user prop from here and the connector.
  user: object.isRequired,
  contexts: array.isRequired,
  isFetchingForms: bool.isRequired,
  isFetchingPermission: bool.isRequired
};

App.defaultProps = {
  selectedContext: undefined
};

const Dashboard = withSnackbar(App);

const DashboardWrapper = props => (
  <AutomaticLogoutChildProvider isSupport={ authenticationHelper.isInternalRole() }>
    <Dashboard { ...props }/>
  </AutomaticLogoutChildProvider>
);

export default DashboardWrapper;
