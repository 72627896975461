import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SovosReadOnlySlidingPanel from 'mosaic-react/sovos-readonly-sliding-panel';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import TINMismatchesTableColumns from './TINMismatchesTableColumns';
import TINMismatchesByCodeTable from './TINMismatchesByCodeTableConnector';
import useColumnSort from 'helpers/useColumnSort';
import './TINMismatches.scss';

const initialSort = { id: TINMismatchesTableColumns[2].id, sortIndicator: TINMismatchesTableColumns[2].sortIndicator };

const TINMismatches = ({ TINMismatchesSummary, actions, fetchingTINMismatchesSummary }) => {
  const [openErrorCodeSlider, setOpenErrorCodeSlider] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const {
    sortOrderIndicator,
    sortColumnIndicator,
    sortColumnId,
    onColumnSortChanged
  } = useColumnSort(initialSort, false);

  useEffect(() => {
    actions.fetchTINMismatchesSummary(sortColumnIndicator, sortOrderIndicator);
  }, [sortColumnIndicator, sortOrderIndicator]);

  const onRowClick = row => {
    setOpenErrorCodeSlider(true);
    setSelectedRow(row);
  };

  const closeErrorCodeSlider = () => {
    setOpenErrorCodeSlider(false);
    actions.fetchTINMismatchesSummary(sortColumnIndicator, sortOrderIndicator);
  };

  return (
    <div>
      <div className="TIN-mismatches__container">
        <SovosTableGroup
          isLoading={ fetchingTINMismatchesSummary }
          TableProps={ {
            columns: TINMismatchesTableColumns,
            data: TINMismatchesSummary,
            onRowClick: onRowClick,
            onColumnSort: onColumnSortChanged,
            columnSortId: sortColumnId
          } }
        />
      </div>
      <SovosReadOnlySlidingPanel
        open={ openErrorCodeSlider }
        onClose={ closeErrorCodeSlider }
        title={ `TIN mismatches - error code ${ selectedRow.IrsCode || '' }` }
      >
        <TINMismatchesByCodeTable
          tinMismatchesSelected={ selectedRow }
        />
      </SovosReadOnlySlidingPanel>
    </div>
  );
};

TINMismatches.propTypes = {
  TINMismatchesSummary: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    fetchTINMismatchesSummary: PropTypes.func
  }).isRequired,
  fetchingTINMismatchesSummary: PropTypes.bool.isRequired
};

export default TINMismatches;