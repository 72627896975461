import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TINMismatchesByCodeTable from './TINMismatchesByCodeTable';
import { fetchMismatchesByCode } from 'redux/recordsSummary/RecordsSummaryActions';
import { setTaxEntityCode, setTaxEntityCodeBase, setFormId } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import { fetchMetadata } from 'shared/dataEntryForms/sovos-dynamic-form/redux/FormMetaInformationActions';
import { exportRecords } from 'shared/recordSlidingPanel/redux/RecordActions';

const mapStateToProps = state => ({
  fetchingMismatchesByCode: state.recordsSummary.fetchingMismatchesByCode,
  mismatchesByCode: state.recordsSummary.mismatchesByCode
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMismatchesByCode, exportRecords }, dispatch),
  formActions: bindActionCreators({ setTaxEntityCode, setTaxEntityCodeBase, setFormId }, dispatch),
  formMetaInformationActions: bindActionCreators({ fetchMetadata }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TINMismatchesByCodeTable);
