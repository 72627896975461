import TinMask from 'shared/dataModifier/tinMask/TinMask';

export default [
  {
    title: 'Name',
    id: 1,
    sortIndicator: 'Name',
    dataKey: 'Name'
  },
  {
    title: 'TIN',
    id: 2,
    width: 'greedy',
    sortIndicator: 'Tin',
    component: ({ content }) => <TinMask records={ { TIN: content.Tin, TINTYPE: content.TinType } } />
  }
];
