const settingType = {
  Configuration: 1,
  Template: 2,
  Map: 3,
  Layout: 4
};

const settingAccess = {
  Profile: 1,
  Client: 2,
  User: 3
};

const updateAction = {
  NotSet: 'NotSet',
  Correct: 'Correct',
  Force: 'Force',
  UpdateWithoutCorrection: 'UpdateWithoutCorrection'
};

const taxDetailStatus = {
  NotSet: 0,
  NonPrinted: 1,
  NonTransmitted: 2,
  Printed: 3,
  Transmitted: 4
};

const importStatus = {
  STARTING: 'Starting',
  RUNNING: 'Running',
  ERROR: 'Error',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  FAILED: 'Failed',
  WAITING: 'Waiting'
};

const transmittalStatus = {
  CANCEL: 'cancel',
  COMPLETE: 'complete'
};

const importType = {
  EXCEL: 'Excel',
  IRS1220: 'IRS1220',
  DELIMITED: 'DelimitedText',
  FIXED: 'FixedLengthText',
  TIM: 'TIM'
};

const importTypeNumber = {
  DelimitedText: 3,
  FixedLengthText: 1
};

const noticeType = {
  NotSet: 'NotSet',
  FirstBNotice: 'FirstBNotice',
  SecondBNotice: 'SecondBNotice',
  W9Notice: 'W9Notice'
};

const productType = {
  TIRC: 'TIR - Corporate',
  TIRE: 'TIR - Enterprise',
  Both: 'Both'
};

const productTypeCode = {
  TIRE: 'TIRE',
  TIRC: 'TIRC',
  Both: 'Both'
};

const noticeName = {
  '1BN': '1st B-notice',
  '2BN': '2nd B-notice',
  'W9R': 'W-9 solicitation'
};

const entImportProcessSteps = {
  importProfile: 0,
  fileConfiguration: 1,
  recordData: 2,
  importBehavior: 3,
  layout: 4,
  dataMapping: 5,
  previewTest: 6,
  importSummary: 7
};

const importProfileProcessStatus = {
  NEW_PROFILE: 'NEW_PROFILE',
  EDIT_PROFILE: 'EDIT_PROFILE',
  READ_ONLY_PROFILE: 'READ_ONLY_PROFILE',
  READ_ONLY_PROFILE_INCOMPLETED: 'READ_ONLY_PROFILE_INCOMPLETED'
};

const setByValues = {
  sovos: 'Sovos',
  client: 'Client',
  template: 'Template',
  batch: 'Batch'
};

const changeType = {
  CREATE_TYPE: 'C',
  UPDATE_TYPE: 'U',
  DELETE_TYPE: 'D'
};

const batchType = {
  NotSet: 0,
  Print: 1,
  StateTransmittal: 3,
  BNotice: 4,
  PNotice: 5,
  ProactiveSolicitation: 6,
  Migration: 7,
  BulkTinMatch: 8,
  CorporateStateTransmittal: 9,
  DocGenFederalTransmittal: 10,
  localityTransmittal: 11,
  extensionOfTimeTransmittal: 12,
  WithholdingOutput: 14
};

const batchFileMetadataType = {
  NotSet: 0,
  StateConfirmationCode: 1,
  CoverPageFileId: 2,
  CorpNecStateTransmitErrorContactInformations: 3,
  FederalConfirmationCode: 4,
  LocalityConfirmationcode: 5,
  ExtensionOfTimeConfirmationCode: 6
};

const activityType = {
  transmit: 4
};

const transmittalPage = {
  FEDERAL: 'federal',
  STATE: 'state',
  LOCALITY: 'locality',
  EXTENSIONOFTIME: 'extensionOfTime'
};

const filterTypes = {
  ...transmittalPage,
  CUSTOMREPORTS: 'custom_reports'
};

const transmittalType = {
  FEDERAL: 'Federal Transmittal',
  STATE: 'State Transmittal',
  LOCALITY: 'Locality Transmittal',
  EOT: 'Extension of time Transmittal'
};

const formCategoriesEOT = {
  2: '1099',
  3: '5498',
  4: '1042-S'
};

const transmitJobState = {
  COMPLETED: 'Completed',
  FAILED: 'Failed'
};

export {
  entImportProcessSteps,
  settingType,
  settingAccess,
  updateAction,
  taxDetailStatus,
  importStatus,
  importType,
  productType,
  productTypeCode,
  importTypeNumber,
  importProfileProcessStatus,
  noticeType,
  noticeName,
  setByValues,
  changeType,
  transmittalStatus,
  batchType,
  batchFileMetadataType,
  activityType,
  transmittalPage,
  transmittalType,
  formCategoriesEOT,
  filterTypes,
  transmitJobState
};