import TabbedOutputOverview from './TabbedOutputOverview/TabbedOutputOverview';
import './dataInformationSection.scss';

const DataInformationSection = () => (
  <div className="data-info-section__wrapper">
    <div className="data-info-section__ent-table">
      <TabbedOutputOverview />
    </div>
  </div>
);

export default DataInformationSection;
