import types from './RecordsSummaryConstants';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const fetchRecordsByFormSummarySuccess = summary => ({ type: types.FETCHING_RECORDS_BY_FORM_SUMMARY_SUCCESS, summary });
const fetchRecordsByFormSuccess = records => ({ type: types.FETCH_RECORDS_BY_FORM_SUCCESS, records });
const fetchTINMismatchesSummarySuccess = summary => ({ type: types.FETCHING_TIN_MISMATCHES_SUMMARY_SUCCESS, summary });
const fetchMismatchesByCodeSuccess = records => ({ type: types.FETCH_MISMATCHES_BY_CODE_SUCCESS, records });

const getSortQueryParam = (sortColumn, isAscending) => sortColumn ? `${ isAscending ? '+' : '-' }${ sortColumn }` : '';

const fetchRecordsByFormSummary = (sortColumn, sortOrder) => async dispatch => {
  const qsParsed = buildUrlsParams(Object.assign({ sortBy: sortOrder + sortColumn }));
  const path = `tax-forms/summary?${qsParsed}`;

  dispatch({ type: types.FETCHING_RECORDS_BY_FORM_SUMMARY });

  try {
    const response = await get(path);
    dispatch(fetchRecordsByFormSummarySuccess(response.data));
  } catch (error) {
    dispatch({ type: types.FETCHING_RECORDS_BY_FORM_SUMMARY_FAILURE });
    throw (error);
  }
};

const fetchRecordsByForm = (fchar, itemsPerPage, pageNumber, searchText, sortColumn, sortOrder) => async dispatch => {
  const qsParsed = buildUrlsParams({ pageSize: itemsPerPage, pageOffset: pageNumber, sortBy: sortOrder + sortColumn });
  const path = `tax-forms/summary/${fchar}?${qsParsed}`;

  dispatch({ type: types.FETCHING_RECORDS_BY_FORM });

  try {
    const response = await post(path, { SearchText: searchText });
    dispatch(fetchRecordsByFormSuccess(response.data));
  } catch (error) {
    throw (error);
  }
};

const fetchTINMismatchesSummary = (sortColumn, sortOrder) => async dispatch => {
  const qsParsed = buildUrlsParams({ sortBy: sortOrder + sortColumn });
  const path = `payees/tin-mismatches/overview?${qsParsed}`;

  dispatch({ type: types.FETCHING_TIN_MISMATCHES_SUMMARY });

  try {
    const response = await get(path);
    dispatch(fetchTINMismatchesSummarySuccess(response.data));
  } catch (error) {
    dispatch({ type: types.FETCH_TIN_MISMATCHES_SUMMARY_FAILURE });
    throw (error);
  }
};

const fetchMismatchesByCode = (irsCode, itemsPerPage, pageNumber, searchText, sortColumn, sortOrder) => async dispatch => {
  dispatch({ type: types.FETCHING_MISMATCHES_BY_CODE });
  const qsParsed = buildUrlsParams(Object.assign({ pageSize: itemsPerPage, pageOffset: pageNumber, auto: true, sortBy: sortOrder + sortColumn }));
  const path = `payees/tin-mismatches/${irsCode}?${qsParsed}`;

  try {
    const response = await post(path, { SearchText: searchText });
    dispatch(fetchMismatchesByCodeSuccess(response.data));
  } catch (error) {
    dispatch({ type: types.FETCH_MISMATCHES_BY_CODE_FAILURE });
    throw (error);
  }
};

const fetchDmfAlerts = (itemsPerPage, pageNumber, sortColumn, isAscending) => async dispatch => {
  dispatch({ type: types.FETCHING_DMF_ALERTS });
  const qsParsed = buildUrlsParams(Object.assign({ pageSize: itemsPerPage, pageOffset: pageNumber, sortBy: getSortQueryParam(sortColumn, isAscending) }));
  const path = `payees/dmf-alerts?${qsParsed}`;

  try {
    const response = await get(path);
    dispatch({ type: types.FETCH_MISMATCHES_DMF_ALERTS_SUCCESS, records: response.data });
  } catch (error) {
    dispatch({ type: types.FETCH_MISMATCHES_DMF_ALERTS_FAILURE });
    throw (error);
  }
};

export {
  fetchRecordsByFormSummary,
  fetchRecordsByForm,
  fetchTINMismatchesSummary,
  fetchMismatchesByCode,
  fetchDmfAlerts
};
