import TinMask from '../../dataModifier/tinMask/TinMask';

export default [
  {
    title: 'TIN',
    id: 1,
    sortIndicator: 'Tin',
    component: ({ content }) => <TinMask records={ { TIN: content.Tin, TINTYPE: content.TinType } } /> // eslint-disable-line react/display-name, react/prop-types
  },
  {
    title: 'Name',
    id: 2,
    sortIndicator: 'Name',
    component: ({ content }) => content.Name ? content.Name : ''
  },
  {
    title: 'Account #',
    id: 3,
    sortIndicator: 'AccountNo',
    component: ({ content }) => content.AccountNo ? content.AccountNo : ''
  },
  {
    title: 'OFAC score',
    align: 'right',
    id: 4,
    sortIndicator: 'OfacRank',
    component: ({ content }) => content.OfacRank ? content.OfacRank : ''
  },
  {
    title: 'City',
    id: 5,
    sortIndicator: 'City',
    component: ({ content }) => content.City ? content.City : ''
  },
  {
    title: 'State',
    id: 6,
    sortIndicator: 'State',
    component: ({ content }) => content.State ? content.State : ''
  },
  {
    title: 'Zip',
    id: 7,
    width: 'greedy',
    sortIndicator: 'Zip',
    component: ({ content }) => content.Zip ? content.Zip : ''
  }
];
