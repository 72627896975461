import ErrorBoundary from './ErrorBoundary';
import SovosPageLayoutNext from 'mosaic-react/sovos-page-layout-next';
import SovosPageContentGeneric from 'mosaic-react/sovos-page-content-generic';

const TirPageLayout = ({ children, excludeContentLayer, ...otherProps }) => (
  <SovosPageLayoutNext { ...otherProps }>
    {
      excludeContentLayer
        ? <ErrorBoundary>
          { children }
        </ErrorBoundary>
        : <SovosPageContentGeneric>
          <ErrorBoundary>
            { children }
          </ErrorBoundary>
        </SovosPageContentGeneric>
    }
  </SovosPageLayoutNext>
);

export default TirPageLayout;