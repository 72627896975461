import React from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';
import 'mosaic-react/installLicense';
import * as serviceWorker from './serviceWorker';
import AuthLayer from './auth/AuthLayer';
import './index.css';

LicenseInfo.setLicenseKey('7ccacf09ad4f9bb08e5976ea50071889Tz02OTIxMixFPTE3MTkwNDk1MTgwNDEsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
  whyDidYouRender(React);
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AuthLayer />
);

serviceWorker.unregister();