import { Component } from 'react';
import PropTypes from 'prop-types';
import DmfAlertsTableColumns from './DmfAlertsTableColumns';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import SlidingFormEditor from 'shared/dataEntryForms/slidingFormEditor/SlidingFormEditorConnector';
import { recipientFchar } from 'helpers/formConstants';

class DmfAlerts extends Component {
  state = {
    itemsPerPage: 20,
    currentPage: 1,
    openFormSlidingPanel: false,
    sortColumn: { id: 0 },
    isAscending: null
  };

  componentDidMount = () => {
    this.props.formMetaInformationActions.fetchMetadata(recipientFchar);
    this.props.actions.fetchDmfAlerts(this.state.itemsPerPage, this.state.currentPage, this.state.sortIndicator, this.state.isAscending);
  };

  handlePerPageChange = (currentPage, itemsPerPage) =>
    this.setState(
      { itemsPerPage, currentPage },
      () => this.props.actions.fetchDmfAlerts(this.state.itemsPerPage, this.state.currentPage, this.state.sortIndicator, this.state.isAscending)
    );

  onRowClick = row => {
    this.props.formActions.setTaxEntityCode(recipientFchar);
    this.props.formActions.setTaxEntityCodeBase(recipientFchar);
    this.props.formActions.setFormId(row.ModRecno);
    this.setState({
      openFormSlidingPanel: true
    });
  };

  onCloseSlidingPanel = () =>
    this.setState(
      { openFormSlidingPanel: false },
      () => this.props.actions.fetchDmfAlerts(this.state.itemsPerPage, this.state.currentPage, this.state.sortIndicator, this.state.isAscending)
    );

  onColumnSortChanged = newColumn => {
    const isAscending = this.state.sortColumn.id === newColumn.id ? !this.state.isAscending : true;
    this.setState({ isAscending, sortColumn: newColumn, currentPage: 1 });
    this.props.actions.fetchDmfAlerts(this.state.itemsPerPage, this.state.currentPage, newColumn.sortIndicator, isAscending);
  };

  render() {
    const { dmfAlerts, fetchingDmfAlerts } = this.props;
    const { itemsPerPage, currentPage, sortColumn } = this.state;

    return (
      <>
        <div>
          <SovosTableGroup
            TableProps={ {
              data: dmfAlerts.Data,
              columns: DmfAlertsTableColumns,
              onRowClick: this.onRowClick,
              onColumnSort: this.onColumnSortChanged,
              columnSortId: sortColumn.id
            } }
            isLoading={ fetchingDmfAlerts }
            PaginationFooterProps={ {
              currentPage,
              itemsPerPage,
              totalItems: dmfAlerts.Total,
              onPageChange: this.handlePerPageChange
            } }
          />
        </div>
        <SlidingFormEditor
          openFormSlidingPanel={ this.state.openFormSlidingPanel }
          onCloseSlidingPanel={ this.onCloseSlidingPanel }
          mountDialog
        />
      </>
    );
  }
}

DmfAlerts.propTypes = {
  dmfAlerts: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    fetchDmfAlerts: PropTypes.func
  }).isRequired,
  formActions: PropTypes.shape({
    setFormId: PropTypes.func,
    setTaxEntityCode: PropTypes.func,
    setTaxEntityCodeBase: PropTypes.func
  }).isRequired,
  formMetaInformationActions: PropTypes.shape({
    fetchMetadata: PropTypes.func
  }).isRequired,
  fetchingDmfAlerts: PropTypes.bool.isRequired
};

export default DmfAlerts;
