export default [
  {
    title: 'IRS code',
    id: 1,
    align: 'left',
    width: 'financial',
    sortIndicator: 'IrsCode',
    dataKey: 'IrsCode'
  },
  {
    title: 'IRS description',
    id: 2,
    sortIndicator: 'Description',
    dataKey: 'Description'
  },
  {
    title: 'Records',
    id: 3,
    align: 'right',
    width: 'greedy',
    sortIndicator: 'RecordsAmount',
    dataKey: 'RecordsAmount'
  }
];
