import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DmfAlerts from './DmfAlerts';
import { fetchDmfAlerts } from 'redux/recordsSummary/RecordsSummaryActions';
import { setFormId, setTaxEntityCode, setTaxEntityCodeBase } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import { fetchMetadata } from 'shared/dataEntryForms/sovos-dynamic-form/redux/FormMetaInformationActions';

const mapStateToProps = state => ({
  fetchingDmfAlerts: state.recordsSummary.fetchingDmfAlerts,
  dmfAlerts: state.recordsSummary.dmfAlerts
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchDmfAlerts }, dispatch),
  formActions: bindActionCreators({ setFormId, setTaxEntityCode, setTaxEntityCodeBase }, dispatch),
  formMetaInformationActions: bindActionCreators({ fetchMetadata }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DmfAlerts);
