import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TINMismatches from './TINMismatches';
import { fetchTINMismatchesSummary } from 'redux/recordsSummary/RecordsSummaryActions';

const mapStateToProps = state => ({
  fetchingTINMismatchesSummary: state.recordsSummary.fetchingTINMismatchesSummary,
  TINMismatchesSummary: state.recordsSummary.TINMismatchesSummary
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchTINMismatchesSummary }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TINMismatches);
