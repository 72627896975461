import { useState, useEffect } from 'react';
import useColumnSort from 'helpers/useColumnSort';
import { get } from 'helpers/axios/axiosInterceptorsV1';
import { withSnackbar } from 'enhancers';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import PropTypes from 'prop-types';
import OfacAlertsTableColumns from './OfacAlertsTableColumns';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import SlidingFormEditor from 'shared/dataEntryForms/slidingFormEditor/SlidingFormEditorConnector';
import { recipientFchar } from 'helpers/formConstants';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const OfacAlerts = ({ formMetaInformationActions, formActions, showErrorSnackbar }) => {
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [openFormSlidingPanel, setOpenFormSlidingPanel] = useState(false);
  const [ofacAlerts, setOfacAlerts] = useState({});
  const [fetchingOfacAlerts, setFetchingOfacAlerts] = useState(false);
  const { sortOrderIndicator, sortColumnIndicator, sortColumnId, onColumnSortChanged } = useColumnSort();

  useEffect(() => {
    formMetaInformationActions.fetchMetadata(recipientFchar);
    fetchOfacAlerts(currentPage, itemsPerPage);
  }, []);

  useEffect(() => {
    if (sortColumnIndicator) {
      setCurrentPage(1);
      fetchOfacAlerts(currentPage, itemsPerPage);
    }
  }, [sortColumnIndicator, sortOrderIndicator]);

  const fetchOfacAlerts = async (pageOffset, pageSize) => {
    setFetchingOfacAlerts(true);
    const qsParsed = buildUrlsParams({ pageSize, pageOffset, sortBy: `${ sortOrderIndicator }${ sortColumnIndicator }` });
    const path = `payees/ofac-alerts?${qsParsed}`;

    try {
      const response = await get(path);
      setOfacAlerts(response.data);
    } catch (error) {
      showErrorSnackbar({ message: getErrorMessage(error) });
    }
    setFetchingOfacAlerts(false);
  };

  const handlePerPageChange = (currentPage, itemsPerPage) => {
    setCurrentPage(currentPage);
    setItemsPerPage(itemsPerPage);
    fetchOfacAlerts(currentPage, itemsPerPage);
  };

  const handleRowClick = row => {
    formActions.setTaxEntityCode(recipientFchar);
    formActions.setTaxEntityCodeBase(recipientFchar);
    formActions.setFormId(row.ModRecno);
    setOpenFormSlidingPanel(true);
  };

  const handleCloseSlidingPanel = () => {
    setOpenFormSlidingPanel(false);
    fetchOfacAlerts(currentPage, itemsPerPage);
  };

  return (
    <>
      <div>
        <SovosTableGroup
          TableProps={ {
            data: ofacAlerts.Data,
            columns: OfacAlertsTableColumns,
            onRowClick: handleRowClick,
            onColumnSort: onColumnSortChanged,
            columnSortId: sortColumnId
          } }
          isLoading={ fetchingOfacAlerts }
          PaginationFooterProps={ {
            currentPage,
            itemsPerPage,
            totalItems: ofacAlerts.Total,
            onPageChange: handlePerPageChange
          } }
        />
      </div>
      <SlidingFormEditor
        openFormSlidingPanel={ openFormSlidingPanel }
        onCloseSlidingPanel={ handleCloseSlidingPanel }
        mountDialog
      />
    </>
  );
};

OfacAlerts.propTypes = {
  formActions: PropTypes.shape({
    setFormId: PropTypes.func,
    setTaxEntityCode: PropTypes.func,
    setTaxEntityCodeBase: PropTypes.func
  }).isRequired,
  formMetaInformationActions: PropTypes.shape({
    fetchMetadata: PropTypes.func
  }).isRequired
};

export default withSnackbar(OfacAlerts);
