import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OfacAlerts from './OfacAlerts';
import { setFormId, setTaxEntityCode, setTaxEntityCodeBase } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import { fetchMetadata } from 'shared/dataEntryForms/sovos-dynamic-form/redux/FormMetaInformationActions';

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFormId, setTaxEntityCode, setTaxEntityCodeBase }, dispatch),
  formMetaInformationActions: bindActionCreators({ fetchMetadata }, dispatch)
});

export default connect(null, mapDispatchToProps)(OfacAlerts);
