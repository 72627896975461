import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import TableChart from '@mui/icons-material/TableChart';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import SovosButton from 'mosaic-react/sovos-button';
import classNames from 'classnames';
import withRouter from 'helpers/withRouter';
import './orderStatusTable.scss';

const OrderStatusTable = ({
  title,
  columns,
  isUpdating,
  data,
  configViewAllButton,
  className,
  noResultsMessage,
  paginationFooterProps,
  onColumnSortChanged,
  sortColumnId,
  actionButtonTestID,
  showMoreButton,
  showMoreUrl,
  onRowClick,
  breadcrumbs
}) => {
  const navigate = useNavigate();

  const actionIconButtons = [
    {
      icon: <TableChart data-testid={ actionButtonTestID } />,
      tooltipText: 'View all',
      onClick: () => navigate(configViewAllButton.to, { state: { breadcrumbs: breadcrumbs } })
    }
  ];

  const isSortable = onColumnSortChanged;

  return (
    <div className={ classNames("pt-order-status", className) }>
      <SovosTableGroup
        className='pt-order-status__table'
        TableProps={ {
          data,
          columns,
          onRowClick,
          onColumnSort: isSortable && onColumnSortChanged,
          columnSortId: isSortable && sortColumnId
        } }
        PaginationFooterProps={ paginationFooterProps && {
          currentPage: paginationFooterProps.currentPage,
          itemsPerPage: paginationFooterProps.itemsPerPage,
          totalItems: paginationFooterProps.totalItems,
          onPageChange: paginationFooterProps.onPageChange
        } }
        isLoading={ isUpdating }
        toolbarTitle={ title }
        showNoData={ !data.length }
        actionIconButtons={ configViewAllButton && actionIconButtons }
        noData={ { headingLabel: noResultsMessage } }
      />
      { showMoreButton && <SovosButton
        className='pt-order-status__showMoreButton'
        data-for='tabbed-output-overview__button-federal'
        variant='text'
        onClick={ () => navigate(`/${showMoreUrl}`, { state: { breadcrumbs: breadcrumbs } }) }
      >
        Show more
      </SovosButton> }
    </div>
  );
};

OrderStatusTable.propTypes = {
  paginationFooterProps: PropTypes.object,
  router: PropTypes.shape({
    navigate: PropTypes.func
  }).isRequired,
  title: PropTypes.string,
  actionButtonDataForLabel: PropTypes.string,
  isUpdating: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onColumnSortChanged: PropTypes.func,
  sortColumnId: PropTypes.string,
  configViewAllButton: PropTypes.object,
  className: PropTypes.string,
  showMoreButton: PropTypes.bool,
  showMoreUrl: PropTypes.string,
  onRowClick: PropTypes.func,
  noResultsMessage: PropTypes.string,
  breadcrumbs: PropTypes.array
};

OrderStatusTable.defaultProps = {
  paginationFooterProps: null,
  actionButtonTestID: 'viewAllButton',
  title: '',
  configViewAllButton: null,
  className: '',
  noResultsMessage: 'Records not found',
  onColumnSortChanged: null,
  showMoreButton: false,
  breadcrumbs: []
};

export default withRouter(OrderStatusTable);