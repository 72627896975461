import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'enhancers';
import useIdentityValidation from './useIdentityValidation';
import { TIR_RTT_USER, TIR_R_VERIFY_IDENTITY } from 'helpers/permissionConstants';
import SovosButton from 'mosaic-react/sovos-button';
import Typography from '@mui/material/Typography';
import authenticationHelper from 'helpers/authenticationHelper';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { REAL_TIME_TIN } from 'helpers/configurationConstants';
import VerificationStatusIcon from 'pages/tinMatching/components/VerificationStatusIcon';
import './identityVerificationUI.scss';

const TIN = 'TIN';
const OFAC = 'OFAC';
const DMF = 'DMF';

const IdentityVerificationUI = ({
  tin,
  name,
  tinType,
  onValidationClick,
  onValidationComplete,
  onResetTin,
  onResetName,
  onResetTinType,
  onSetNewTinValidationStatus,
  dmfStatus,
  ofacStatus,
  isTiaTheTinStatusSource,
  modrecno,
  showErrorSnackbar
}) => {
  const [hasRealTimeTin] = authenticationHelper.checkConfiguration([REAL_TIME_TIN]);

  const {
    storedTinStatus,
    tiaTinStatus,
    ofacResult,
    dmfResult,
    getTinStatus,
    runTinValidation,
    runOfacCheck,
    runDmfCheck,
    isTinStatusAwaiting,
    isTinValidating,
    isOfacFetching,
    isDmfFetching,
    legacyStatusAndTiaStatusMatch
  } = useIdentityValidation(tin, name, tinType, onResetTin, onResetName, onResetTinType);

  const [isTiaSource, setIsTiaSource] = useState(isTiaTheTinStatusSource);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (isTiaSource && modrecno) {
        getTinStatusBasedTIM(modrecno);
      }
      return;
    }
    if (!isTinValidating && !isOfacFetching && !isDmfFetching) {
      onValidationComplete(tiaTinStatus, ofacResult, dmfResult, !legacyStatusAndTiaStatusMatch);
    }
  }, [isTinValidating, isOfacFetching, isDmfFetching]);

  useEffect(() => {
    if (tiaTinStatus?.Status === 'U' && !isTinValidating) {
      showErrorSnackbar({ message: "IRS is unavailable. Please try again later." });
    }
  }, [tiaTinStatus, isTinValidating]);

  const getTinStatusBasedTIM = async () => {
    const newTinValidationStatus = await getTinStatus(modrecno);
    onSetNewTinValidationStatus(newTinValidationStatus);
  };

  const isConfigurationsRequiredEnabled = () => hasRealTimeTin;

  const handleValidateClick = async () => {
    setIsTiaSource(false);
    onValidationClick();
    try {
      await runTinValidation();
    } catch (error) {
      showErrorSnackbar({ message: getErrorMessage(error) });
    }
    if (hasRealTimeTin) {
      runOfacCheck();
      runDmfCheck();
    }
  };

  const isDisabled = () => (
    isTinValidating || isOfacFetching || isDmfFetching || tin === undefined || tin.length !== 9 || !name
  );

  return (
    isConfigurationsRequiredEnabled() &&
    <div className="identity-verification__container">
      <Typography variant="h4" className="identity-verification__verification-label">Identity management</Typography>
      <div>
        {
          isTiaSource
            ? <VerificationStatusIcon
              type={ TIN }
              fetching={ isTinStatusAwaiting }
              acceptedValue={ 3 }
              value={ storedTinStatus?.Status }
              isTiaTheTinStatusSource={ isTiaTheTinStatusSource }
            />
            : <VerificationStatusIcon
              type={ TIN }
              fetching={ isTinValidating }
              acceptedValue='A'
              value={ tiaTinStatus?.Status }
              irsStatus = { tiaTinStatus?.IrsStatusCode }
            />
        }
        <br/>
        <VerificationStatusIcon
          type={ OFAC }
          fetching={ isOfacFetching }
          acceptedValue='P'
          value={ ofacStatus || ofacResult?.Status }
        />
        <br/>
        <VerificationStatusIcon
          type={ DMF }
          fetching={ isDmfFetching }
          acceptedValue='P'
          value={ dmfStatus || dmfResult }
        />
        <br/>
      </div>
      {
        authenticationHelper.checkSomeUserPermissions([TIR_R_VERIFY_IDENTITY, TIR_RTT_USER]) &&
        hasRealTimeTin &&
        <div>
          <SovosButton id="btnValidate" onClick={ handleValidateClick } disabled={ isDisabled() } variant="text">
            Verify identity
          </SovosButton>
        </div>
      }
    </div>
  );
};

IdentityVerificationUI.propTypes = {
  tin: PropTypes.string,
  name: PropTypes.string,
  tinType: PropTypes.string,
  onValidationClick: PropTypes.func,
  onValidationComplete: PropTypes.func,
  onResetTin: PropTypes.func,
  onResetName: PropTypes.func,
  onResetTinType: PropTypes.func,
  onSetNewTinValidationStatus: PropTypes.func,
  dmfStatus: PropTypes.string,
  ofacStatus: PropTypes.string,
  isTiaTheTinStatusSource: PropTypes.bool,
  modrecno: PropTypes.number,
  showErrorSnackbar: PropTypes.func.isRequired
};

IdentityVerificationUI.defaultProps = {
  tin: '',
  name: '',
  modrecno: null,
  isTiaTheTinStatusSource: false,
  onValidationClick: () => null,
  onValidationComplete: () => null,
  onSetNewTinValidationStatus: () => null
};

export default withSnackbar(IdentityVerificationUI);