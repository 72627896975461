import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import GetAppIcon from '@mui/icons-material/GetApp';
import SlidingFormEditor from 'shared/dataEntryForms/slidingFormEditor/SlidingFormEditorConnector';
import { recipientFchar } from 'helpers/formConstants';
import useColumnSort from 'helpers/useColumnSort';
import TINMismatchesByCodeTableColumns from './TINMismatchesByCodeTableColumns';

const TINMismatchesByCodeTable = ({
  tinMismatchesSelected,
  mismatchesByCode,
  actions: { fetchMismatchesByCode, exportRecords },
  formActions: { setFormId, setTaxEntityCode, setTaxEntityCodeBase },
  formMetaInformationActions: { fetchMetadata },
  fetchingMismatchesByCode
}) => {

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [openFormSlidingPanel, setOpenFormSlidingPanel] = useState(false);
  const [searchText, setSearchText] = useState('');

  const {
    sortOrderIndicator,
    sortColumnIndicator,
    sortColumnId,
    onColumnSortChanged
  } = useColumnSort();

  useEffect(() => {
    fetchMetadata(recipientFchar);
  }, []);

  useEffect(() => {
    if (!openFormSlidingPanel) {
      fetchMismatchesByCode(
        tinMismatchesSelected.IrsCode,
        itemsPerPage,
        currentPage,
        searchText,
        sortColumnIndicator,
        sortOrderIndicator
      );
    }
  }, [openFormSlidingPanel, fetchMismatchesByCode, tinMismatchesSelected, itemsPerPage, currentPage, searchText, sortColumnIndicator, sortOrderIndicator]);

  const handlePerPageChange = (currentPage, itemsPerPage) => {
    setCurrentPage(currentPage);
    setItemsPerPage(itemsPerPage);
  };

  const handleDownload = () => exportRecords('1', '', '', searchText, tinMismatchesSelected.IrsCode);

  const handleSearch = value => {
    setCurrentPage(1);
    setSearchText(value);
  };

  const onRowClick = row => {
    setTaxEntityCode(recipientFchar);
    setTaxEntityCodeBase(recipientFchar);
    setFormId(row.ModRecno);
    setOpenFormSlidingPanel(true);
  };

  const onCloseSlidingPanel = () => setOpenFormSlidingPanel(false);

  return (
    <div>
      <div className="record-sliding-panel__margin">
        {
          mismatchesByCode.Data &&
            <SovosTableGroup
              isLoading={ fetchingMismatchesByCode }
              toolbarTitle={ tinMismatchesSelected.Description }
              noResults={ {
                headingLabel: `No records available matching search term: ${searchText}`,
                helpLabel: 'Please try a new search'
              } }
              TableProps={ {
                columns: TINMismatchesByCodeTableColumns,
                data: mismatchesByCode.Data,
                onRowClick: onRowClick,
                onColumnSort: onColumnSortChanged,
                columnSortId: sortColumnId
              } }
              PaginationFooterProps={ {
                currentPage,
                itemsPerPage,
                totalItems: mismatchesByCode.Total,
                onPageChange: handlePerPageChange
              } }
              search={ {
                term: searchText,
                onSearch: handleSearch
              } }
              actionIconButtons={ [{
                icon: <GetAppIcon />,
                onClick: handleDownload,
                tooltipText: 'Download'
              }] }
            />
        }
      </div>
      <SlidingFormEditor
        openFormSlidingPanel={ openFormSlidingPanel }
        onCloseSlidingPanel={ onCloseSlidingPanel }
        mountDialog
      />
    </div>
  );
};

TINMismatchesByCodeTable.propTypes = {
  tinMismatchesSelected: PropTypes.object.isRequired,
  mismatchesByCode: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    fetchMismatchesByCode: PropTypes.func,
    exportRecords: PropTypes.func
  }).isRequired,
  formActions: PropTypes.shape({
    setTaxEntityCode: PropTypes.func,
    setTaxEntityCodeBase: PropTypes.func,
    setFormId: PropTypes.func
  }).isRequired,
  formMetaInformationActions: PropTypes.shape({
    fetchMetadata: PropTypes.func
  }).isRequired,
  fetchingMismatchesByCode: PropTypes.bool.isRequired
};

export default TINMismatchesByCodeTable;